import axios from '@jslib/axios'

export const cancelQuotationMotor = data =>{
  return axios({
    method: 'post',
    url: '/admin/cancellation/motors',
    data
  })
}
export const cancelQuotationConstrator = data =>{
  return axios({
    method: 'post',
    url: '/admin/cancellation/risks',
    data
  })
}
export const cancelQuotationTravel = data =>{
  return axios({
    method: 'post',
    url: '/admin/cancellation/travel',
    data
  })
}
export const cancelQuotationHome = data =>{
  return axios({
    method: 'post',
    url: '/admin/cancellation/home',
    data
  })
}
export const cancelQuotationEmployee = data =>{
  return axios({
    method: 'post',
    url: '/admin/cancellation/employee/compensation',
    data
  })
}
export const cancelQuotationBusiness = data =>{
  return axios({
    method: 'post',
    url: '/admin/cancellation/business/package',
    data
  })
}
export const cancelQuotationDomestic = data =>{
  return axios({
    method: 'post',
    url: '/admin/cancellation/domestic/helper',
    data
  })
}
export const cancelQuotationContruction = data =>{
  return axios({
    method: 'post',
    url: '/admin/cancellation/construction/employee/compensation',
    data
  })
}
export const cancelQuotationOther = data =>{
  return axios({
    method: 'post',
    url: '/admin/cancellation/others',
    data
  })
}
export const computedCancel = data=>{
  return axios({
    method: 'post',
    url: '/admin/cancellation/quotation',
    data
  })
}